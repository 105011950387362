<template>
  <div class="container">
    <div class="form-card mb-20">
      <div class="card-head">
        <h4 class="card-title">Müşteri Ekle</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="Ad"
              :value="formData.name"
              keyName="formData.name"
              type="text"
              :className="v$.formData.name.$errors.length > 0 ? 'error' : ''"
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.name.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="E-mail"
              :value="formData.contact_email"
              keyName="formData.contact_email"
              type="text"
              :className="
                v$.formData.contact_email.$errors.length > 0 ? 'error' : ''
              "
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.contact_email.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>

          <template v-if="false">
            <div class="col-md-4">
              <div class="form-group">
                <label for="">İl</label>
                <Multiselect
                  v-model="formData.city"
                  :options="cityList"
                  placeholder=""
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                ></Multiselect>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">İlçe</label>
                <Multiselect
                  v-model="formData.town"
                  :options="townList"
                  placeholder=""
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                ></Multiselect>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">Mahalle</label>
                <Multiselect
                  v-model="formData.neighborhood"
                  :options="neighborhoodList"
                  placeholder=""
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                ></Multiselect>
              </div>
            </div>
            <div class="col-md-4">
              <TextTemplate
                label="Posta Kodu"
                :value="formData.zip_code"
                keyName="formData.zip_code"
                type="text"
                @onChangeText="handleText"
              />
            </div>
            <div class="col-md-12">
              <TextTemplate
                label="Adres"
                :value="formData.address"
                keyName="formData.address"
                type="textarea"
                @onChangeText="handleText"
              />
            </div>
            <div class="col-md-4">
              <TextTemplate
                label="Vergi No"
                :value="formData.tax_number"
                keyName="formData.tax_number"
                type="text"
                @onChangeText="handleText"
              />
            </div>
            <div class="col-md-4">
              <TextTemplate
                label="Vergi Dairesi"
                :value="formData.tax_office"
                keyName="formData.tax_office"
                type="text"
                @onChangeText="handleText"
              />
            </div>
          </template>
        </div>
      </div>
      <button class="btn btn-filled-primary mt-20 ml-auto" @click="addCustomer">
        Ekle
      </button>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import { helpers, required, email } from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useValidate(),
      formData: {
        name: "",
        contact_email: "",
        city: "",
        town: "",
        neighborhood: "",
        zip_code: "",
        address: "",
        tax_number: "",
        tax_office: "",
      },
      cityList: ["İstanbul"],
      townList: ["Bağcılar"],
      neighborhoodList: ["Barbaros Mah."],
    };
  },
  validations() {
    return {
      formData: {
        name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        contact_email: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          email: helpers.withMessage(
            "Lütfen geçerli bir e-mail giriniz.",
            email
          ),
          $autoDirty: true,
        },
      },
    };
  },

  async created() {
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Müşteriler",
        path: "/customers",
      },
      {
        title: "Müşteri Ekle",
      },
    ]);
  },
  methods: {
    addCustomer() {
      this.v$.formData.$touch();
      if (!this.v$.formData.$error) {
        this.$axios
          .post("/customer/", this.formData)
          .then(async (response) => {
            this.$router.replace({
              name: "CustomerEdit",
              params: { id: response.data.id },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleText(data) {
      let keynames = data.keyName.split(".");
      keynames.length <= 1
        ? (this[keynames[0]] = data.value)
        : (this[keynames[0]][keynames[1]] = data.value);
    },
  },
};
</script>